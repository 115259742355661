import {useContext} from 'react';
import {Row, Col} from 'react-bootstrap';

import {GlobalContext} from '../../../context/GlobalState';

import {orders} from '../../../helper/Values';

import Vehicles from './Vehicles/VehiclesHome';
import WantAnotherVehicle from './Vehicles/WantOtherVehicle';
import InsuredWith from './InsuredWith/InsuredWithHome';
import HowManyDrivers from './Drivers/HowManyDrivers';
import DriversHome from './Drivers/DriversHome';
import OthersHome from './Others/OthersHome';
import EndHome from './End/EndHome';

const Home = () => {
  const {currentStep} = useContext(GlobalContext);

  return (
    <Row className="justify-content-center form-body">
      <Col xs={12}>
        {currentStep === orders.stepVehicles ? (
          <Vehicles></Vehicles>
        ) : currentStep === orders.stepWantOtherVehicles ? (
          <WantAnotherVehicle></WantAnotherVehicle>
        ) : currentStep === orders.stepInsuranceQuestion1 ||
          currentStep === orders.stepInsuranceQuestion2 ||
          currentStep === orders.stepInsuranceQuestion3 ||
          currentStep === orders.stepInsuranceQuestion4 ? (
          <InsuredWith></InsuredWith>
        ) : currentStep === orders.stepHowManyDrivers ? (
          <HowManyDrivers></HowManyDrivers>
        ) : currentStep === orders.stepDrivers ? (
          <DriversHome></DriversHome>
        ) : currentStep === orders.stepOwnHome ||
          currentStep === orders.stepDUI ||
          currentStep === orders.stepSrFr ||
          currentStep === orders.stepAccidents ||
          currentStep === orders.stepTickets ||
          currentStep === orders.stepHomeAddress ||
          currentStep === orders.stepHowLongAddress ||
          currentStep === orders.stepEmail ||
          currentStep === orders.stepPhone ? (
          <OthersHome></OthersHome>
        ) : (
          <EndHome></EndHome>
        )}
      </Col>
    </Row>
  );
};

export default Home;
