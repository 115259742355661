import {createContext, useReducer} from 'react';

import {defaultVehicleValue, baseState, orders} from '../helper/Values';

import AppReducer from './AppReducer';
import {decideNextStep, decidePreviousStep} from '../helper/Functions';

// Initial State
const initialState = {
  ...baseState,
  currentStep: 1,
  currentRepeater: 1,
  currentSubStep: 1,
  currentURL: '',
  numberOfVehicles: 1,

  isGoingBackDirection: false,
  transitions: 0,
  sentToBeSaved: false,
  newTab: false,
};

// Create Context
export const GlobalContext = createContext(initialState);

// Provider
export const GlobalProvider = ({children}) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  //Actions
  const updateCurrentStep = (step) => {
    dispatch({type: 'UPDATE_CURRENT_STEP', payload: step});
  };

  const updateCurrentRepeater = (repeater) => {
    dispatch({type: 'UPDATE_CURRENT_REPEATER', payload: repeater});
  };

  const updateCurrentSubStep = (step) => {
    dispatch({type: 'UPDATE_CURRENT_SUB_STEP', payload: step});
  };

  const updateCurrentURL = (url) => {
    dispatch({type: 'UPDATE_CURRENT_URL', payload: url});
  };

  const updateStep = (
    value,
    key = 'value',
    step = state.currentStep,
    repeater = state.currentRepeater,
    subStep = state.currentSubStep
  ) => {
    if (!state.steps[step].isRepeaterStep)
      dispatch({
        type: 'UPDATE_SINGLE_STEP',
        payload: {step, key, value},
      });
    else
      dispatch({
        type: 'UPDATE_REPEATER_STEP',
        payload: {step, repeater, subStep, key, value},
      });
  };

  const updateFirstName = (value) => {
    const {currentStep, currentRepeater, currentSubStep} = state;

    updateStep(value, 'firstName');
  };

  const updateLastName = (value) => {
    const {currentStep, currentRepeater, currentSubStep} = state;

    updateStep(value, 'lastName');
  };

  const addNewVehicle = () => {
    const indexOfNewVehicle = state.numberOfVehicles + 1;
    dispatch({
      type: 'ADD_NEW_VEHICLE',
      payload: {
        currentStep: orders.stepVehicles,
        indexOfNewVehicle,
        vehicleArr: defaultVehicleValue(),
      },
    });

    updateNumberOfVehicles(indexOfNewVehicle);
    updateCurrentStep(orders.stepVehicles);
    updateCurrentRepeater(indexOfNewVehicle);
    updateCurrentSubStep(1);
  };

  const addNewDriver = async (driverNumber, driverObj) => {
    const {steps, currentStep} = state;
    const driversStepIndex = orders.stepDrivers;

    dispatch({
      type: 'ADD_NEW_DRIVER',
      payload: {driverNumber, driverObj, driversStepIndex},
    });
  };

  const removeExtraDrivers = (numberOfDriversToKeep) => {
    const {steps} = state;
    const driversStepIndex = orders.stepDrivers;

    const oldDriversObj = steps[orders.stepDrivers].value;
    let driversObj = {};

    for (let i = 1; i <= numberOfDriversToKeep; ++i) {
      driversObj[i] = oldDriversObj[i];
    }
    dispatch({
      type: 'SET_DRIVERS_LIST',
      payload: {driversObj, driversStepIndex},
    });
  };

  const updateNumberOfVehicles = (value) => {
    dispatch({
      type: 'UPDATE_NUMBER_OF_VEHICLES',
      payload: value,
    });
  };

  const updateIsGoingBackDirection = (value) => {
    dispatch({type: 'UPDATE_IS_GOING_BACK_DIRECTION', payload: value});
  };

  const updateTransitions = () => {
    const {transitions} = state;
    dispatch({type: 'UPDATE_TRANSITIONS', payload: transitions + 1});
  };

  const updateSentToBeSaved = (value) => {
    dispatch({type: 'UPDATE_SENT_TO_BE_SAVED', payload: value});
  };

  const updateNewTab = (value) => {
    dispatch({type: 'UPDATE_NEW_TAB', payload: value});
  };

  //--------- Extra Navigation Actions ---------//
  const handleChange = (value) => {
    updateStep(value);
  };

  const incrementStep = () => {
    // If last step, don't do anything as we can't go front further
    if (state.currentStep === orders.stepLastStep) return;
    const {nextStep, nextRepeater, nextSubStep, nextURL} =
      decideNextStep(state);

    updateIsGoingBackDirection(false);
    updateCurrentStep(nextStep);
    updateCurrentRepeater(nextRepeater);
    updateCurrentSubStep(nextSubStep);
    updateCurrentURL(nextURL);
    updateTransitions();
  };

  const decrementStep = () => {
    // If first step already, don't do anything as we can't go further back
    if (state.currentStep === 1) return;
    updateStep('');
    const {previousStep, previousRepeater, previousSubStep, previousURL} =
      decidePreviousStep(state);

    updateIsGoingBackDirection(true);
    updateCurrentStep(previousStep);
    updateCurrentRepeater(previousRepeater);
    updateCurrentSubStep(previousSubStep);
    updateCurrentURL(previousURL);
    updateTransitions();
  };

  // This checks if it is to be skipped only for single steps, or the whole repeater step (can't check a specific repeater for skipping flag)
  const checkToBeSkipped = () => {
    const {steps, currentStep} = state;

    // If to be skipped found then just skip this step no matter what (either single or repeater)
    if (steps[currentStep].toBeSkipped) return true;
    else {
      // If not to be skipped as shown in condition above, and it's single, simply return false to forbid skipping
      if (!steps[currentStep].isRepeaterStep) return false;
      // If not to be skipped as shown in condition above, and it's repeater, go and check toBeSkipped value in the main level or with each repeater
      else return getToBeSkippedValue();
    }
  };

  const getToBeSkippedValue = () => {
    const {steps, currentStep, currentRepeater, currentSubStep} = state;
    // If single step, just check first level
    if (!steps[currentStep].isRepeaterStep)
      return steps[currentStep].toBeSkipped;
    // If repeater, start by checking the main level of repeater
    else if (steps[currentStep].value[currentRepeater].toBeSkipped) return true;
    // If repeater, and whole repeater is not skipped, start checking if each value is to be skipped
    else
      return steps[currentStep].value[currentRepeater][currentSubStep]
        .toBeSkipped;
  };

  const getStepValue = (
    step = state.currentStep,
    repeater = state.currentRepeater,
    subStep = state.subStep,
    key = 'value',
    searchOnlyFirstLevel
  ) => {
    const {steps} = state;
    if (!steps[step].isRepeaterStep || searchOnlyFirstLevel)
      return steps[step][key];
    else return steps[step].value[repeater][subStep][key];
  };
  //--------- Extra Navigation Actions ---------//

  return (
    <GlobalContext.Provider
      value={{
        currentStep: state.currentStep,
        currentRepeater: state.currentRepeater,
        currentSubStep: state.currentSubStep,
        currentURL: state.currentURL,
        steps: state.steps,

        numberOfVehicles: state.numberOfVehicles,
        isGoingBackDirection: state.isGoingBackDirection,
        transitions: state.transitions,
        sentToBeSaved: state.sentToBeSaved,
        newTab: state.newTab,

        updateCurrentStep,
        updateCurrentRepeater,
        updateCurrentSubStep,
        updateCurrentURL,
        updateStep,
        updateFirstName,
        updateLastName,
        updateSentToBeSaved,
        updateNewTab,

        addNewVehicle,
        addNewDriver,
        removeExtraDrivers,

        handleChange,
        incrementStep,
        decrementStep,
        getStepValue,
        checkToBeSkipped,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
