import {useState, useEffect, useContext} from 'react';
import {Row, Col, Button, Spinner} from 'react-bootstrap';
import axios from 'axios';

import {GlobalContext} from '../../../../context/GlobalState';

import {
  successStr,
  failureStr,
  resubmitButtonStr,
} from '../../../../helper/Strings';

import {
  airtable,
  orders,
  options,
  automationLink,
  redirectionWebsite,
  delayBeforeRedirectionAfterSubmissionInSeconds,
} from '../../../../helper/Values';

const SaveResults = () => {
  const [isSuccess, setIsSuccess] = useState('loading');
  const {steps, sentToBeSaved, updateSentToBeSaved} = useContext(GlobalContext);

  const initiateSaveSteps = async () => {
    const saveSteps = async () => {
      if (!sentToBeSaved) {
        updateSentToBeSaved(true);

        const isInsured =
          steps[orders.stepInsuranceQuestion1].value ===
          options.insuredWithGeneralOptionsArray[0]
            ? false
            : true;
        const saveRes = await axios.post(`/helper/steps`, {
          steps,
          airtable,
          orders,
          isInsured,
          automationLink,
        });
        const response = saveRes.data;

        if (response.success) {
          setIsSuccess(true);

          setTimeout(() => {
            window.location.href = redirectionWebsite;
          }, delayBeforeRedirectionAfterSubmissionInSeconds * 1000);
        } else {
          setIsSuccess(false);
        }
      }
    };

    try {
      await saveSteps();
    } catch (err) {
      setIsSuccess(false);
    }
  };
  useEffect(async () => {
    initiateSaveSteps();
  });

  return (
    <Row>
      {isSuccess === 'loading' ? (
        <Col xs={12} className={'d-flex justify-content-center'}>
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>{' '}
        </Col>
      ) : isSuccess ? (
        <h5>{successStr}</h5>
      ) : (
        <Col>
          <Col xs={12}>
            <h5>{failureStr}</h5>
          </Col>
          <Col xs={12} className="text-center">
            {' '}
            <Button
              onClick={async () => {
                updateSentToBeSaved(false);
                setIsSuccess('loading');
                await initiateSaveSteps();
              }}
              className="submit-button"
            >
              {resubmitButtonStr}
            </Button>
          </Col>
        </Col>
      )}
    </Row>
  );
};

export default SaveResults;
