import {useContext} from 'react';
import {Row, Col, Button} from 'react-bootstrap';

import {GlobalContext} from '../../../context/GlobalState';

const TemplateButtonsQuestion = ({
  question,
  options,
  buttonsWidth,
  buttonsSmallerWidth,
  classes,
}) => {
  const {handleChange, incrementStep} = useContext(GlobalContext);

  return (
    <>
      <Row>
        <Col xs={12}>
          <h3>{question}</h3>
        </Col>
        {options.map((option) => {
          return (
            <Col
              xs={buttonsSmallerWidth ? buttonsSmallerWidth : buttonsWidth}
              md={buttonsWidth}
              key={option}
              className="text-center"
            >
              <Button
                value={option}
                className={`${classes}`}
                onClick={(e) => {
                  handleChange(e.target.value);
                  incrementStep();
                }}
              >
                {option}
              </Button>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default TemplateButtonsQuestion;
