import {useContext} from 'react';
import {Row, Col} from 'react-bootstrap';

import {GlobalContext} from '../../../../context/GlobalState';

import {orders, options} from '../../../../helper/Values';
import {
  ownHomeStr,
  duiStr,
  srFrStr,
  accidentsStr,
  ticketsStr,
  howLongAddressStr,
} from '../../../../helper/Strings';

import HomeAddress from './HomeAddress';
import Email from './Email';
import Phone from './Phone';
import TemplateButtonsQuestion from '../TemplateButtonsQuestion';

const OthersHome = () => {
  const {currentStep} = useContext(GlobalContext);

  return (
    <Row>
      <Col xs={12}>
        {currentStep === orders.stepOwnHome ? (
          <TemplateButtonsQuestion
            question={ownHomeStr.question}
            options={options.ownHome}
            buttonsWidth={12}
            classes="medium-button"
          ></TemplateButtonsQuestion>
        ) : currentStep === orders.stepDUI ? (
          <TemplateButtonsQuestion
            question={duiStr.question}
            options={options.yesNo}
            buttonsWidth={6}
            classes="small-button"
          ></TemplateButtonsQuestion>
        ) : currentStep === orders.stepSrFr ? (
          <TemplateButtonsQuestion
            question={srFrStr.question}
            options={options.yesNo}
            buttonsWidth={6}
            classes="small-button"
          ></TemplateButtonsQuestion>
        ) : currentStep === orders.stepAccidents ? (
          <TemplateButtonsQuestion
            question={accidentsStr.question}
            options={options.numbersUpTo}
            buttonsWidth={6}
            buttonsSmallerWidth={12}
            classes="medium-button"
          ></TemplateButtonsQuestion>
        ) : currentStep === orders.stepTickets ? (
          <TemplateButtonsQuestion
            question={ticketsStr.question}
            options={options.numbersUpTo}
            buttonsWidth={6}
            classes="medium-button"
            buttonsSmallerWidth={12}
          ></TemplateButtonsQuestion>
        ) : currentStep === orders.stepHomeAddress ? (
          <HomeAddress></HomeAddress>
        ) : currentStep === orders.stepHowLongAddress ? (
          <TemplateButtonsQuestion
            question={howLongAddressStr.question}
            options={options.howLongAddress}
            buttonsWidth={12}
            classes="big-button"
          ></TemplateButtonsQuestion>
        ) : currentStep === orders.stepEmail ? (
          <Email></Email>
        ) : currentStep === orders.stepPhone ? (
          <Phone></Phone>
        ) : (
          <></>
        )}
      </Col>
    </Row>
  );
};

export default OthersHome;
