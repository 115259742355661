import {useContext} from 'react';
import {Row, Col, Button} from 'react-bootstrap';

import {GlobalContext} from '../../../../context/GlobalState';

import {countElementsInJSON} from '../../../../helper/Functions';
import {defaultDriversValue, options, orders} from '../../../../helper/Values';
import {howManyDriversStr} from '../../../../helper/Strings';

const HowManyDrivers = () => {
  const {steps, incrementStep, addNewDriver, removeExtraDrivers} =
    useContext(GlobalContext);

  const handleHowManyDriversSubmission = (value) => {
    const currentNumberOfDrives = countElementsInJSON(
      steps[orders.stepDrivers].value
    );
    const numberOfDriversNeeded = value;
    const driversDefaultObj = defaultDriversValue();

    if (currentNumberOfDrives < numberOfDriversNeeded)
      for (let i = currentNumberOfDrives + 1; i <= numberOfDriversNeeded; ++i)
        addNewDriver(i, driversDefaultObj);
    else if (currentNumberOfDrives > numberOfDriversNeeded)
      removeExtraDrivers(numberOfDriversNeeded);

    incrementStep();
  };

  let numbers = [];
  for (let i = 1; i <= options.driversMaximumNumber; ++i) {
    numbers.push(i);
  }

  return (
    <>
      <Row>
        <Col xs={12}>
          <h3>{howManyDriversStr.question}</h3>
        </Col>
      </Row>
      <Row>
        {numbers.map((number) => {
          return (
            <Col xs={3} key={number}>
              <Button
                className={'medium-button'}
                value={number}
                onClick={async () => {
                  handleHowManyDriversSubmission(number);
                }}
              >
                {number}
              </Button>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default HowManyDrivers;
