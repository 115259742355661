import {useContext} from 'react';
import {Row, Col, Button} from 'react-bootstrap';

import {GlobalContext} from '../../../../context/GlobalState';

import {options} from '../../../../helper/Values';
import {wantOtherVehicle} from '../../../../helper/Strings';

const WantOtherVehicle = () => {
  const {incrementStep, addNewVehicle} = useContext(GlobalContext);

  return (
    <Row>
      <Col xs={12}>
        <h3>{wantOtherVehicle.question}</h3>
      </Col>
      <Col xs={6} className="text-center">
        <Button
          onClick={() => {
            addNewVehicle();
          }}
          className="small-button"
        >
          {options.yesNo[0]}
        </Button>
      </Col>
      <Col className="text-center">
        <Button
          onClick={() => {
            incrementStep();
          }}
          className="small-button"
        >
          {options.yesNo[1]}
        </Button>
      </Col>
    </Row>
  );
};

export default WantOtherVehicle;
