import {useContext} from 'react';
import {Row, Col} from 'react-bootstrap';

import {GlobalContext} from '../../../../context/GlobalState';

import {generateYearsArray, generateString} from '../../../../helper/Functions';
import {orders, options} from '../../../../helper/Values';
import {
  yearsStr,
  makingPaymentsStr,
  usageStateStr,
  milesPerYearArr,
} from '../../../../helper/Strings';

import Make from './Make';
import Model from './Model';
import SubModel from './SubModel';
import TemplateButtonsQuestion from '../TemplateButtonsQuestion';
import TemplateButtonsSelectQuestion from '../TemplateButtonsSelectQuestion';

const VehiclesHome = () => {
  const {currentStep, currentSubStep, currentRepeater, steps} =
    useContext(GlobalContext);

  const year =
    steps[currentStep].value[currentRepeater][orders.stepVehiclesYear].value;
  const make =
    steps[currentStep].value[currentRepeater][orders.stepVehiclesMake].value;
  const model =
    steps[currentStep].value[currentRepeater][orders.stepVehiclesModel].value;

  let yearsArray = generateYearsArray();
  let buttonsYears = yearsArray.slice(
    0,
    options.numberOfVehicleYearToShowOnButtons - 1
  );
  let selectYears = yearsArray.slice(
    options.numberOfVehicleYearToShowOnButtons - 1
  );

  return (
    <Row>
      <Col xs={12}>
        {currentSubStep === orders.stepVehiclesYear ? (
          <TemplateButtonsSelectQuestion
            question={yearsStr.question}
            selectPlaceholder={yearsStr.selectPlaceholder}
            buttonOptions={buttonsYears}
            selectOptions={selectYears}
            buttonsWidth={3}
            buttonsSmallerWidth={6}
            classes="medium-button"
          ></TemplateButtonsSelectQuestion>
        ) : currentSubStep === orders.stepVehiclesMake ? (
          <Make></Make>
        ) : currentSubStep === orders.stepVehiclesModel ? (
          <Model></Model>
        ) : currentSubStep === orders.stepVehiclesSubModel ? (
          <SubModel></SubModel>
        ) : currentSubStep === orders.stepVehiclesMakingPayments ? (
          <TemplateButtonsQuestion
            question={generateString(
              makingPaymentsStr.question,
              ['year', 'make', 'model'],
              [year, make, model]
            )}
            options={options.makingPaymentsArray}
            buttonsWidth={12}
            classes="big-button"
          ></TemplateButtonsQuestion>
        ) : currentSubStep === orders.stepVehiclesUsage ? (
          <TemplateButtonsQuestion
            question={generateString(
              usageStateStr.question,
              ['year', 'make', 'model'],
              [year, make, model]
            )}
            options={options.usageStateArray}
            buttonsWidth={12}
            classes="big-button"
          ></TemplateButtonsQuestion>
        ) : currentSubStep === orders.stepVehiclesMiles ? (
          <TemplateButtonsQuestion
            question={generateString(
              milesPerYearArr.question,
              ['year', 'make', 'model'],
              [year, make, model]
            )}
            options={options.milesPerYearArray}
            buttonsWidth={12}
            classes="big-button"
          ></TemplateButtonsQuestion>
        ) : (
          <></>
        )}
      </Col>
    </Row>
  );
};

export default VehiclesHome;
