import {useContext, useState} from 'react';
import {Row, Col, Form, Button} from 'react-bootstrap';

import {GlobalContext} from '../../../context/GlobalState';

import {handleEnterClickOnInput} from '../../../helper/Functions';
import {orders} from '../../../helper/Values';
import {zipCodeStr} from '../../../helper/Strings';

const ZipCode = () => {
  const [error, setError] = useState(false);
  const {steps, newTab, handleChange, incrementStep} =
    useContext(GlobalContext);

  const handleZipSubmission = () => {
    const value = steps[orders.stepZipCode].value;
    if (value !== '' && value.length === 5) {
      if (newTab) incrementStep();
      else window.top.location.href = `/?code=${value}`;
    } else setError(true);
  };

  return (
    <Row className="zip-code-div text-center">
      <Col xs={12}>
        <Form
          onSubmit={(event) => {
            handleEnterClickOnInput(event, () => {
              handleZipSubmission();
            });
          }}
        >
          <Form.Group controlId="formZipCode">
            <Form.Label>
              <h3 className="zip-code-title">{zipCodeStr.question}</h3>
            </Form.Label>
            <Form.Control
              isInvalid={error}
              type="text"
              placeholder={zipCodeStr.placeholder}
              autoFocus
              value={steps[orders.stepZipCode].value}
              className="zip-code-field"
              onChange={(e) => {
                setError(false);
                handleChange(e.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid" className="white">
              {zipCodeStr.validation}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Col>
      <Col xs={12}>
        <Row>
          <Col xs={{span: 8, offset: 2}} md={{span: 6, offset: 3}}>
            <Button
              onClick={() => {
                handleZipSubmission();
              }}
              className="begin-button"
            >
              {zipCodeStr.button}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ZipCode;
