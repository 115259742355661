import {useContext, useState} from 'react';
import {Row, Col, Form, Button} from 'react-bootstrap';
import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';

import {GlobalContext} from '../../../../context/GlobalState';

import {handleEnterClickOnInput} from '../../../../helper/Functions';
import {orders} from '../../../../helper/Values';
import {homeAddressStr} from '../../../../helper/Strings';

const HomeAddress = () => {
  const [empty, setEmpty] = useState(false);
  const {steps, currentStep, handleChange, incrementStep} =
    useContext(GlobalContext);

  const onSelect = (value) => {
    setEmpty(false);
    handleChange(value);
  };
  const onChange = (value) => {
    setEmpty(false);
    handleChange(value);
  };

  const handleHomeAddressSubmission = () => {
    if (steps[orders.stepHomeAddress].value !== '') incrementStep();
    else setEmpty(true);
  };

  return (
    <Row>
      <Col xs={12}>
        <PlacesAutocomplete
          value={steps[currentStep].value}
          onChange={onChange}
          onSelect={onSelect}
          onClick={onSelect}
          searchOptions={{
            componentRestrictions: {country: 'us'},
          }}
        >
          {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
            <div>
              <Form
                {...getInputProps({})}
                onSubmit={(event) => {
                  handleEnterClickOnInput(event, () => {
                    handleHomeAddressSubmission();
                  });
                }}
              >
                <Form.Group controlId="homeAddress">
                  <Form.Label>
                    <h3>{homeAddressStr.question}</h3>
                  </Form.Label>
                  <Form.Control
                    {...getInputProps({})}
                    isInvalid={empty}
                    type="text"
                    placeholder={homeAddressStr.placeholder}
                    autoFocus
                    value={steps[orders.stepHomeAddress].value}
                  />
                  <Form.Control.Feedback type="invalid">
                    {homeAddressStr.validation}
                  </Form.Control.Feedback>
                  <>
                    {loading ? <div>... loading</div> : null}
                    {suggestions.map((suggestion) => {
                      const style = {
                        backgroundColor: suggestion.active ? '#f9bcdd' : '#fff',
                      };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            style,
                          })}
                          key={suggestion.description}
                        >
                          {suggestion.description}
                        </div>
                      );
                    })}
                  </>
                </Form.Group>
              </Form>
            </div>
          )}
        </PlacesAutocomplete>
      </Col>
      <Col xs={12} className="text-center">
        <Button
          onClick={() => {
            handleHomeAddressSubmission();
          }}
          className="next-button"
        >
          {homeAddressStr.button}
        </Button>
      </Col>
    </Row>
  );
};

export default HomeAddress;
