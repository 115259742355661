import {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import Select from 'react-select';
import {Row, Col, Image, Spinner} from 'react-bootstrap';

import {GlobalContext} from '../../../../context/GlobalState';

import {orders, options} from '../../../../helper/Values';
import {makesStr} from '../../../../helper/Strings';

const Make = () => {
  const {
    steps,
    currentStep,
    currentRepeater,
    handleChange,
    incrementStep,
    isGoingBackDirection,
    decrementStep,
  } = useContext(GlobalContext);

  const [makesWithPictures, setMakesWithPictures] = useState([]);
  const [makesWithoutPictures, setMakesWithoutPictures] = useState([]);
  const [valueRetrieved, setValueRetrieved] = useState(false);

  const year =
    steps[currentStep].value[currentRepeater][orders.stepVehiclesYear].value;

  useEffect(() => {
    const getMakes = async () => {
      try {
        const makesRes = await axios.get(`/helper/make?year=${year}`);
        const {makes} = makesRes.data;

        if (makes.length > 0) {
          let makesWithPicturesArr = [];
          let makesWithoutPicturesArr = [];

          makes.forEach(({make}) => {
            if (options.makesWithPicturesNames.includes(make))
              makesWithPicturesArr.push(make);
            else
              makesWithoutPicturesArr.push({
                value: make,
                label: make,
              });
          });
          setMakesWithPictures(makesWithPicturesArr);
          setMakesWithoutPictures(makesWithoutPicturesArr);
          setValueRetrieved(true);
        } else {
          if (isGoingBackDirection) decrementStep();
          else incrementStep();
        }
      } catch (err) {
        console.dir(err, {depth: 10});
        decrementStep();
      }
    };

    getMakes();
  }, []);

  return (
    <Row>
      {makesWithPictures.length > 0 && makesWithoutPictures.length > 0 ? (
        <>
          <Col xs={12}>
            <h3>{makesStr.question}</h3>
          </Col>
          {makesWithPictures.map((make) => {
            return (
              <Col xs={12} sm={6} md={3} key={make} className="text-center">
                <Image
                  className={'make-logo'}
                  src={`/images/cars-logos/${make}.png`}
                  onClick={() => {
                    handleChange(make);
                    incrementStep();
                  }}
                />
              </Col>
            );
          })}
          <Col xs={12}>
            <Select
              className={'select'}
              options={makesWithoutPictures}
              placeholder={makesStr.selectPlaceholder}
              onChange={(selectedMake) => {
                handleChange(selectedMake.value);
                incrementStep();
              }}
            ></Select>
          </Col>
        </>
      ) : valueRetrieved ? (
        <Col xs={12} className={'d-flex justify-content-center'}>
          No makes were retrieved, go back and correct car information.
        </Col>
      ) : (
        <Col xs={12} className={'d-flex justify-content-center'}>
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </Col>
      )}
    </Row>
  );
};

export default Make;
