import {useContext, useEffect, useState} from 'react';
import {Row, Col} from 'react-bootstrap';

import {GlobalContext} from '../../../../context/GlobalState';

import TemplateButtonsQuestion from '../TemplateButtonsQuestion';
import TemplateButtonsSelectQuestion from '../TemplateButtonsSelectQuestion';

import {generateString} from '../../../../helper/Functions';
import {orders, options} from '../../../../helper/Values';
import {
  insuredWithGeneralOptionsStr,
  howLongWithInsuranceCompanyStr,
  howLongContinuousCoverageStr,
  bodilyInjuryLimitStr,
  howLongSinceInsuranceStr,
  liabilityLimitLikedStr,
} from '../../../../helper/Strings';

const InsuredWithHome = () => {
  const {
    steps,
    currentStep,
    isGoingBackDirection,
    updateStep,
    incrementStep,
    decrementStep,
  } = useContext(GlobalContext);

  const insuredStatus = {
    insured: 'insured',
    notInsured: 'notInsured',
    unknown: 'unknown',
  };

  const [isInsured, setIsInsured] = useState(false);

  useEffect(() => {
    if (currentStep === orders.stepInsuranceQuestion1) {
      updateStep('');
      setIsInsured(insuredStatus.unknown);
      updateStep('', undefined, orders.stepInsuranceQuestion4);
      updateStep(true, 'toBeSkipped', orders.stepInsuranceQuestion4);
    } else {
      if (
        steps[orders.stepInsuranceQuestion1].value ===
        options.insuredWithGeneralOptionsArray[0]
      ) {
        setIsInsured(insuredStatus.notInsured);
        updateStep(true, 'toBeSkipped', orders.stepInsuranceQuestion4);
      } else {
        setIsInsured(insuredStatus.insured);
        updateStep(false, 'toBeSkipped', orders.stepInsuranceQuestion4);

        // In addition to the above action, check if insurance has been lengthy, and hide 3 question if needed
        if (currentStep === orders.stepInsuranceQuestion3) {
          if (
            steps[orders.stepInsuranceQuestion2].value ===
              options.howLongWithInsuranceCompanyArray[2] ||
            steps[orders.stepInsuranceQuestion2].value ===
              options.howLongWithInsuranceCompanyArray[3]
          ) {
            if (isGoingBackDirection) decrementStep();
            else incrementStep();
          }
        }
      }
    }
  }, [currentStep, isInsured]);

  return (
    <Row>
      <Col xs={12}>
        {currentStep === orders.stepInsuranceQuestion1 ? (
          <TemplateButtonsSelectQuestion
            question={insuredWithGeneralOptionsStr.question}
            selectPlaceholder={insuredWithGeneralOptionsStr.selectPlaceholder}
            buttonOptions={options.insuredWithGeneralOptionsArray}
            selectOptions={options.insuredWithOtherOptionsArray}
            buttonsWidth={12}
            classes="big-button"
          ></TemplateButtonsSelectQuestion>
        ) : isInsured === insuredStatus.unknown ? (
          <></>
        ) : isInsured === insuredStatus.insured ? (
          <>
            {currentStep === orders.stepInsuranceQuestion2 ? (
              <TemplateButtonsQuestion
                question={generateString(
                  howLongWithInsuranceCompanyStr.question,
                  ['company'],
                  [steps[orders.stepInsuranceQuestion1].value]
                )}
                options={options.howLongWithInsuranceCompanyArray}
                buttonsWidth={12}
                classes="big-button"
              ></TemplateButtonsQuestion>
            ) : currentStep === orders.stepInsuranceQuestion3 ? (
              <TemplateButtonsQuestion
                question={generateString(
                  howLongContinuousCoverageStr.question,
                  ['company'],
                  [steps[orders.stepInsuranceQuestion1].value]
                )}
                options={options.howLongContinuousCoverageArray}
                buttonsWidth={12}
                classes="big-button"
              ></TemplateButtonsQuestion>
            ) : currentStep === orders.stepInsuranceQuestion4 ? (
              <TemplateButtonsQuestion
                question={bodilyInjuryLimitStr.question}
                options={options.bodilyInjuryLimitArray}
                buttonsWidth={12}
                classes="big-button"
              ></TemplateButtonsQuestion>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            {currentStep === orders.stepInsuranceQuestion2 ? (
              <TemplateButtonsQuestion
                question={howLongSinceInsuranceStr.question}
                options={options.howLongSinceInsuranceArray}
                buttonsWidth={12}
                classes="big-button"
              ></TemplateButtonsQuestion>
            ) : currentStep === orders.stepInsuranceQuestion3 ? (
              <TemplateButtonsQuestion
                question={liabilityLimitLikedStr.question}
                options={options.liabilityLimitLikedArray}
                buttonsWidth={12}
                classes="big-button"
              ></TemplateButtonsQuestion>
            ) : (
              <></>
            )}
          </>
        )}
      </Col>
    </Row>
  );
};

export default InsuredWithHome;
