import {useContext} from 'react';
import {Row, Col} from 'react-bootstrap';
import ordinal from 'ordinal';

import {GlobalContext} from '../../../../context/GlobalState';

import {generateString} from '../../../../helper/Functions';
import {orders, options} from '../../../../helper/Values';
import {driverStr} from '../../../../helper/Strings';

import Name from './Name';
import DOB from './DOB';
import TemplateButtonsQuestion from '../TemplateButtonsQuestion';
import TemplateButtonsSelectQuestion from '../TemplateButtonsSelectQuestion';

const DriversHome = () => {
  const {steps, currentStep, currentSubStep, currentRepeater} =
    useContext(GlobalContext);

  return (
    <Row>
      <Col xs={12}>
        {currentSubStep === orders.stepDriversRelationship ? (
          <TemplateButtonsQuestion
            question={generateString(
              driverStr.relationship.question,
              ['ordinal'],
              [ordinal(currentRepeater)]
            )}
            options={options.relations}
            buttonsWidth={12}
            classes="medium-button"
          ></TemplateButtonsQuestion>
        ) : currentSubStep === orders.stepDriversName ? (
          <Name></Name>
        ) : currentSubStep === orders.stepDriversGender ? (
          <TemplateButtonsQuestion
            question={
              currentRepeater === 1
                ? driverStr.gender.questionSelf
                : generateString(
                    driverStr.gender.questionOthers,
                    ['firstName'],
                    [
                      steps[currentStep].value[currentRepeater][
                        orders.stepDriversName
                      ].firstName,
                    ]
                  )
            }
            options={options.genders}
            buttonsWidth={6}
            classes="medium-button"
          ></TemplateButtonsQuestion>
        ) : currentSubStep === orders.stepDriversDOB ? (
          <DOB></DOB>
        ) : currentSubStep === orders.stepDriversMartialStatus ? (
          <TemplateButtonsQuestion
            question={
              currentRepeater === 1
                ? driverStr.martialStatus.questionSelf
                : generateString(
                    driverStr.martialStatus.questionOthers,
                    ['firstName'],
                    [
                      steps[currentStep].value[currentRepeater][
                        orders.stepDriversName
                      ].firstName,
                    ]
                  )
            }
            options={options.martialStatus}
            buttonsWidth={12}
            classes="medium-button"
          ></TemplateButtonsQuestion>
        ) : currentSubStep === orders.stepDriversEducationLevel ? (
          <TemplateButtonsQuestion
            question={
              currentRepeater === 1
                ? driverStr.education.questionSelf
                : generateString(
                    driverStr.education.questionOthers,
                    ['firstName'],
                    [
                      steps[currentStep].value[currentRepeater][
                        orders.stepDriversName
                      ].firstName,
                    ]
                  )
            }
            options={options.educationLevels}
            buttonsWidth={12}
            classes="medium-button"
          ></TemplateButtonsQuestion>
        ) : currentSubStep === orders.stepDriversOccupation ? (
          <TemplateButtonsSelectQuestion
            question={
              currentRepeater === 1
                ? driverStr.occupation.questionSelf
                : generateString(
                    driverStr.occupation.questionOthers,
                    ['firstName'],
                    [
                      steps[currentStep].value[currentRepeater][
                        orders.stepDriversName
                      ].firstName,
                    ]
                  )
            }
            selectPlaceholder={'Choose Other'}
            buttonOptions={options.occupationsButtons}
            selectOptions={options.occupationsSelect}
            buttonsWidth={12}
            classes="medium-button"
          ></TemplateButtonsSelectQuestion>
        ) : (
          <></>
        )}
      </Col>
    </Row>
  );
};

export default DriversHome;
