import {countElementsInJSON} from './Functions';

export const automationLink = `https://hooks.zapier.com/hooks/catch/9082034/bohijfb/`;

export const redirectionWebsite = `https://insureNerds.com`;

export const delayBeforeRedirectionAfterSubmissionInSeconds = 5;

export const defaultVehicleValue = () => {
  return {
    1: {
      url: `year`,
      value: ``,
    },
    2: {
      url: `make`,
      value: ``,
    },
    3: {
      url: `model`,
      value: ``,
    },
    4: {
      url: `subModel`,
      value: ``,
    },
    5: {url: `making-payments`, value: ``},
    6: {url: `usage`, value: ``},
    7: {url: `miles`, value: ``},
    toBeSkipped: false,
  };
};

export const defaultDriversValue = () => {
  return {
    1: {
      url: `relation`,
      value: ``,
    },
    2: {
      url: `name`,
      value: ``,
      firstName: ``,
      lastName: ``,
    },
    3: {
      url: `gender`,
      value: ``,
    },
    4: {
      url: `dob`,
      value: ``,
    },
    5: {
      url: `martial-status`,
      value: ``,
    },
    6: {url: `education-level`, value: ``},
    7: {url: `occupation`, value: ``},
    toBeSkipped: false,
  };
};
export const baseState = {
  // These steps assumes that no 2 repeaters steps would come next to each other,
  // and each repeater steps has a following step as a flag or question to ask user if they want to add repeater
  // the increment and decrement steps are depending on the above being held true all the time
  steps: {
    1: {
      url: `zip`,
      value: ``,
    },

    2: {
      url: `vehicle`,
      value: {
        1: defaultVehicleValue(),
      },
      isRepeaterStep: true,
      numberOfSubSteps: countElementsInJSON(defaultVehicleValue()) - 1,
    },

    3: {
      url: `other-vehicle?`,
      value: ``,
      isRepeaterFlag: true,
    },

    4: {
      url: `insurance-1`,
      value: ``,
    },

    5: {
      url: `insurance-2`,
      value: ``,
    },

    6: {
      url: `insurance-3`,
      value: ``,
    },

    7: {
      url: `insurance-4`,
      value: ``,
      toBeSkipped: false,
    },

    8: {
      url: `how-many-drivers`,
      value: ``,
    },

    9: {
      url: `driver`,
      value: {
        1: {
          ...defaultDriversValue(),
          [1]: {
            ...defaultDriversValue()[1],
            value: `self`,
            toBeSkipped: true,
          },
        },
      },
      isRepeaterStep: true,
      numberOfSubSteps: countElementsInJSON(defaultDriversValue()) - 1,
    },

    10: {
      url: `own-home`,
      value: ``,
      followingRepeater: true,
    },

    11: {
      url: `dui`,
      value: ``,
    },

    12: {
      url: `sr-fr`,
      value: ``,
    },

    13: {
      url: `accidents`,
      value: ``,
    },

    14: {
      url: `tickets`,
      value: ``,
    },

    15: {
      url: `home-address`,
      value: ``,
    },

    16: {url: `duration-in-home`, value: ``},

    17: {url: `email`, value: ``},

    18: {url: `phone`, value: ``},

    19: {url: `thank-you`, value: ``},
  },
};

// This order is in related to the way the questions are stored, please do not change it without knowledge
export const orders = {
  stepZipCode: 1,
  stepVehicles: 2,
  stepWantOtherVehicles: 3,
  stepInsuranceQuestion1: 4,
  stepInsuranceQuestion2: 5,
  stepInsuranceQuestion3: 6,
  stepInsuranceQuestion4: 7,
  stepHowManyDrivers: 8,
  stepDrivers: 9,
  stepOwnHome: 10,
  stepDUI: 11,
  stepSrFr: 12,
  stepAccidents: 13,
  stepTickets: 14,
  stepHomeAddress: 15,
  stepHowLongAddress: 16,
  stepEmail: 17,
  stepPhone: 18,
  stepLastStep: 19,

  stepVehiclesYear: 1,
  stepVehiclesMake: 2,
  stepVehiclesModel: 3,
  stepVehiclesSubModel: 4,
  stepVehiclesMakingPayments: 5,
  stepVehiclesUsage: 6,
  stepVehiclesMiles: 7,

  stepDriversRelationship: 1,
  stepDriversName: 2,
  stepDriversGender: 3,
  stepDriversDOB: 4,
  stepDriversMartialStatus: 5,
  stepDriversEducationLevel: 6,
  stepDriversOccupation: 7,
};

export const options = {
  numberOfVehicleYearToShowOnButtons: 19,
  numberOfVehicleYearsToShow: 42,
  numberOfVehicleYearsShowNextYear: true,
  makesWithPicturesNames: [
    `Ford`,
    `GMC`,
    `Honda`,
    `Hyundai`,
    `Jeep`,
    `Kia`,
    `Mercedes`,
    `Nissan`,
    `Ram`,
    `Subaru`,
    `Toyota`,
  ],
  makingPaymentsArray: [
    `Making Payments`,
    `Leased Vehicle`,
    `Own With No Money Owed`,
  ],
  usageStateArray: [
    `Drive to work or school (less than 15 miles one way)`,
    `Drive to work or school (more than 15 miles one way)`,
    `Pleasure`,
    `Delivery, Uber or Lyft`,
    `Business Use`,
    `Farm`,
  ],
  milesPerYearArray: [
    `Less than 5,000`,
    `5,000 to 10,000`,
    `10,000 to 15,000`,
    `15,000 to 20,000`,
    `More than 20,000`,
  ],
  insuredWithGeneralOptionsArray: [
    `Not Currently Insured`,
    `Allstate`,
    `Auto Owners`,
    `Erie`,
    `Farm Bureau`,
    `Farmers`,
    `Geico`,
    `Liberty Mutual`,
    `Nationwide`,
    `Progressive`,
    `State Farm`,
    `Travelers`,
    `USAA`,
  ],
  insuredWithOtherOptionsArray: [
    `Agency Ins Company of Maryland`,
    `Allstate`,
    `Bristol West`,
    `Cincinnati`,
    `Donegal`,
    `Elephant`,
    `Erie`,
    `Esurance`,
    `Farm Bureau`,
    `Farmers`,
    `Geico`,
    `Grange Mutual`,
    `Hanover Insurance Group`,
    `Hartford`,
    `Liberty Mutual`,
    `Main Street America`,
    `Mercury`,
    `MetLife`,
    `National General`,
    `Nationwide`,
    `Penn National`,
    `Progressive`,
    `Rockingham Casualty`,
    `Safeco`,
    `Selective Insurance`,
    `Sentry Insurance`,
    `State Auto`,
    `State Farm`,
    `Travelers`,
    `USAA`,
    `Other`,
  ],
  howLongWithInsuranceCompanyArray: [
    `Less Than 1 Year`,
    `1 to 2 Years`,
    `More Than 2 Years but Less Than 5`,
    `5 or More Years`,
  ],
  howLongContinuousCoverageArray: [
    `Less Than 6 Months`,
    `6 Months to 1 Year`,
    `More Than 1 Year but Less Than 2`,
    `2 Years or More`,
  ],
  bodilyInjuryLimitArray: [
    `State Minimum`,
    `Average (Higher Than Minimum But Less Than $100,000 per person)`,
    `Better than average ($100,000 per person or higher)`,
  ],
  howLongSinceInsuranceArray: [
    `Less than 30 days`,
    `30 to 60 days`,
    `More than 60 days`,
  ],
  liabilityLimitLikedArray: [`Only What Is Required`, `Good`, `Better`, `Best`],
  driversMinimumNumber: 1,
  driversMaximumNumber: 6,
  relations: [
    `Spouse`,
    `Parent`,
    `Child`,
    `Fiancé`,
    `Significant Other`,
    `Other`,
  ],
  genders: [`Male`, `Female`],
  martialStatus: [`Single`, `Married`, `Separated`, `Divorced`, `Widowed`],
  educationLevels: [
    `No High School Diploma or GED`,
    `High School Diploma or GED`,
    `Vocational or Trade School Degree`,
    `Military Training`,
    `Completed Some College`,
    `Associates Degree`,
    `Bachelor's Degree`,
    `Graduate Work or Graduate Degree`,
  ],
  occupationsButtons: [
    `Customer Service Rep`,
    `Doctor`,
    `Laborer/Worker`,
    `Police Officer`,
    `Registered Nurse`,
    `Retail Salesperson`,
    `Retired`,
    `Student`,
    `Unemployed`,
    `Waiter/Waitress`,
  ],
  occupationsSelect: [
    `Account Executive`,
    `Accountant/Auditor`,
    `Activity Assistant`,
    `Actor`,
    `Actuary`,
    `Admin. Assistant`,
    `Administrators`,
    `Ag Inspector`,
    `Agent/Broker`,
    `Air Traffic Control`,
    `Airport Operations`,
    `Analyst`,
    `Analyst Broker`,
    `Animal Control`,
    `Arborist`,
    `Architect`,
    `Artist`,
    `Assistant-Medical`,
    `Athlete`,
    `Attorney`,
    `Author/Writer`,
    `Bailiff`,
    `Baker`,
    `Barber`,
    `Bartender`,
    `Bellhop`,
    `Bellhop/Porter`,
    `Boiler Operator`,
    `Bookkeeper`,
    `Branch Manager`,
    `Bricklayer/Mason`,
    `Broadcaster`,
    `Bus Person`,
    `Camp Counselor`,
    `Caregiver`,
    `Carpenter`,
    `Carpet Installer`,
    `Chef`,
    `Claim Rep/Adjuster`,
    `Clergy`,
    `Clerk`,
    `Clerk-Office`,
    `Coach`,
    `Collections`,
    `Composer/Director`,
    `Computer Repair`,
    `Concierge`,
    `Concrete Worker`,
    `Construction-P.M.`,
    `Consultant`,
    `Cook-Fast Food`,
    `Cook-Restaurant`,
    `Correction Officer`,
    `Counselor`,
    `Crane Loader`,
    `Crane Operator`,
    `CSR/Teller`,
    `Cust. Service Rep.`,
    `Custodian/Janitor`,
    `Dancer`,
    `Dental Hygienist`,
    `Dentist`,
    `Deputy Sheriff`,
    `Designer`,
    `Desk Clerk`,
    `Dishwasher`,
    `Dispatcher`,
    `Doctor`,
    `Drafter`,
    `Driver-Bus`,
    `Driver-Limo`,
    `Dry Cleaner`,
    `Editor`,
    `Electrician`,
    `Electrician-Lineman`,
    `Engineer`,
    `Engineer-Hardware`,
    `Engineer-Software`,
    `Equipment Operator`,
    `Examiner`,
    `Executive`,
    `Farm Owner/Worker`,
    `Financial Advisor`,
    `Fire Chief`,
    `Firefighter`,
    `Fisherman`,
    `Food Production`,
    `Funeral Director`,
    `Gaming Officer`,
    `Graduate Student`,
    `Hair Stylist`,
    `Handyman`,
    `High School`,
    `Highway Patrol`,
    `Hospice Volunteers`,
    `Host/Maître d`,
    `Housekeeper`,
    `Housekeeper/Maid`,
    `HVAC Repair`,
    `HVAC Technician`,
    `Inspector`,
    `Investment Banker`,
    `Investor`,
    `Journalist/Reporter`,
    `Judge`,
    `Laborer/Worker`,
    `Landscaping`,
    `Legislator`,
    `Librarian/Curator`,
    `Lifeguard`,
    `Loan Processor`,
    `Logger`,
    `Maintenance`,
    `Manager`,
    `Manicurist`,
    `Marketing Manager`,
    `Masseuse`,
    `Mathematician`,
    `Metalworker`,
    `Meter Reader`,
    `Military-Air Force`,
    `Military-Army`,
    `Military-Marines`,
    `Military-Navy`,
    `Military-Other`,
    `Military-USCG`,
    `Millworker`,
    `Miner`,
    `Manager Systems`,
    `Manager - Credit/Loan`,
    `Manager - Finance`,
    `Manager - H.R./P.R.`,
    `Manager - Property`,
    `Manager - Retail Sales`,
    `Manager - Sales Other`,
    `Musician/Singer`,
    `Nanny/Child Care`,
    `Network Admin.`,
    `Nurse - CNA`,
    `Nurse - LN`,
    `Nurse - RN`,
    `Optometrist`,
    `Painter`,
    `Paralegal`,
    `Park Ranger`,
    `Parking Lot Attend.`,
    `Pilot/Captain/Crew`,
    `Planner`,
    `Plaster/Drywall`,
    `Plumber`,
    `Police Chief`,
    `Police Detective`,
    `Police Officer`,
    `Postmaster`,
    `Producer`,
    `Production Crew`,
    `Programmer`,
    `Project Engineer`,
    `Private Investigator`,
    `Railroad Worker`,
    `Ranger`,
    `Realtor`,
    `Receptionist`,
    `Researcher`,
    `Roofer`,
    `Route/Vendor`,
    `Sales - Retail`,
    `Sales - Counter`,
    `Sales - Manufacturing Rep`,
    `Scientist`,
    `Security Guard`,
    `Sheriff`,
    `Social Worker`,
    `Sports Media`,
    `Superintendent`,
    `Supervisor`,
    `Support Technician`,
    `Surveyor/Mapmaker`,
    `Teacher-Other`,
    `Teacher-Special Ed`,
    `Teaching Assistant`,
    `Technician`,
    `Therapist`,
    `Ticket Agent`,
    `Ticket Sales/Usher`,
    `Trainer/Instructor`,
    `Travel Agent`,
    `Umpire/Referee`,
    `Undergraduate`,
    `Underwriter`,
    `Veterinarian`,
    `Waiter/Waitress`,
    `Other`,
  ],
  ownHome: [
    `Own a Home`,
    `Own a Townhome`,
    `Own a Condo`,
    `Own Mobile home`,
    `Rent`,
    `Live with Parents`,
  ],
  howLongAddress: [
    `Less than 2 months`,
    `Less than 1 year but more than 2 months`,
    `More than 1 year but less than 2 years`,
    `2 or more years`,
  ],
  //
  yesNo: [`Yes`, `No`],
  numbersUpTo: [`0`, `1`, `2`, `3 or more`],
};

export const airtable = {
  requests: {
    name: `Requests`,
    fields: {
      primaryName: `Name`,
      zipCode: `Zip Code`,
      insuredWith: `Insurance Company`,
      insured1: `Insurance Length`,
      insured2: `Continuous Coverage Length`,
      insured3: `Bodily Injury Liability Limit`,
      notInsured1: `Time since Last Insurance`,
      notInsured2: `Liability Limit`,
      ownHome: `Own a Home`,
      dui: `DUI or Reckless Driving`,
      srFR: `SR and FR`,
      faultAccidents: `Fault Accidents`,
      speedingTickets: `Speeding Tickets`,
      homeAddress: `Home Address`,
      howLongAddress: `How Long in Address`,
      email: `Email`,
      phone: `Phone Number`,
    },
  },
  vehicles: {
    name: `Cars`,
    fields: {
      year: `Year`,
      make: `Make`,
      model: `Model`,
      subModel: `Sub Model`,
      paymentsStatus: `Payments Status`,
      usage: `Usage`,
      miles: `How Many Miles Average`,
      request: `Request`,
    },
  },
  drivers: {
    name: `Drivers`,
    fields: {
      firstName: `First Name`,
      lastName: `Last Name`,
      relationship: `Relationship`,
      gender: `Gender`,
      dob: `Date of Birth`,
      martialStatus: `Martial Status`,
      educationLevel: `Eduction Level`,
      occupation: `Occupation`,
      request: `Request`,
    },
  },
};
