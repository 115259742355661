import {useContext, useState} from 'react';
import {Row, Col, Form, Button} from 'react-bootstrap';
import moment from 'moment';

import {GlobalContext} from '../../../../context/GlobalState';

import {
  handleEnterClickOnInput,
  getNumbersToArray,
  generateString,
} from '../../../../helper/Functions';
import {orders} from '../../../../helper/Values';
import {driverStr} from '../../../../helper/Strings';

const DOB = () => {
  const [error, setError] = useState(false);
  const {steps, currentStep, currentRepeater, handleChange, incrementStep} =
    useContext(GlobalContext);

  const checkIfFollowsDatePattern = async (value) => {
    const date = moment(value, 'MM/DD/YYYY', true);

    if (date.isValid() && date.isBefore(moment())) return true;
    else return false;
  };

  const formatDate = async (value) => {
    let n = await getNumbersToArray(value);
    let str = '';

    for (let i = 0; i < 10; ++i) {
      if (!n[i]) n[i] = '';
    }

    if (value.length < 3) str = `${n[0]}${n[1]}`;
    else if (value.length < 6) str = `${n[0]}${n[1]}/${n[2]}${n[3]}`;
    else str = `${n[0]}${n[1]}/${n[2]}${n[3]}/${n[4]}${n[5]}${n[6]}${n[7]}`;

    return str;
  };

  const handleDOBSubmission = async () => {
    const dobValue =
      steps[orders.stepDrivers].value[currentRepeater][orders.stepDriversDOB]
        .value;
    if (await checkIfFollowsDatePattern(dobValue)) {
      incrementStep();
    } else setError(true);
  };

  return (
    <Row>
      <Col xs={12}>
        <Form
          onSubmit={(event) => {
            handleEnterClickOnInput(event, () => {
              handleDOBSubmission();
            });
          }}
        >
          <Form.Group controlId="dob">
            <Form.Label>
              <h3>
                {currentRepeater === 1
                  ? driverStr.dateOfBirth.questionSelf
                  : generateString(
                      driverStr.dateOfBirth.questionOthers,
                      ['firstName'],
                      [
                        steps[currentStep].value[currentRepeater][
                          orders.stepDriversName
                        ].firstName,
                      ]
                    )}
              </h3>
            </Form.Label>
            <Form.Control
              isInvalid={error}
              type="texts"
              placeholder={driverStr.dateOfBirth.placeholder}
              autoFocus
              value={
                steps[orders.stepDrivers].value[currentRepeater][
                  orders.stepDriversDOB
                ].value
              }
              className="dob-field"
              onChange={async (e) => {
                const value = e.target.value;
                const lastButtonClicked = e.nativeEvent.data;

                // Check if lastButtonClicked defined and it's not number, because might an auto complete and this means
                // the lastButton will be undefined and that's not a number and this means it will disable auto complete
                if (lastButtonClicked && isNaN(lastButtonClicked)) return;

                let formattedValue = await formatDate(value);
                handleChange(formattedValue);
              }}
            />
            <Form.Control.Feedback type="invalid">
              {driverStr.dateOfBirth.validation}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Col>
      <Col xs={12} className="text-center">
        <Button
          onClick={() => {
            handleDOBSubmission();
          }}
          className="next-button"
        >
          {driverStr.dateOfBirth.button}
        </Button>
      </Col>
    </Row>
  );
};

export default DOB;
