import {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import {Row, Col, Button, Spinner} from 'react-bootstrap';

import {GlobalContext} from '../../../../context/GlobalState';

import {generateString} from '../../../../helper/Functions';
import {orders} from '../../../../helper/Values';
import {modelsStr} from '../../../../helper/Strings';

const Model = () => {
  const {
    steps,
    currentStep,
    currentRepeater,
    isGoingBackDirection,
    handleChange,
    incrementStep,
    decrementStep,
  } = useContext(GlobalContext);

  const [models, setModels] = useState([]);
  const [valueRetrieved, setValueRetrieved] = useState(false);

  const year =
    steps[currentStep].value[currentRepeater][orders.stepVehiclesYear].value;
  const make =
    steps[currentStep].value[currentRepeater][orders.stepVehiclesMake].value;

  useEffect(() => {
    const getModels = async () => {
      try {
        const modelsRes = await axios.get(
          `/helper/models?year=${year}&make=${make}`
        );
        const models = modelsRes.data;

        if (models.length > 0) {
          setModels(models);
          setValueRetrieved(true);
        } else {
          if (isGoingBackDirection) decrementStep();
          else incrementStep();
        }
      } catch (err) {
        console.dir(err, {depth: 10});
        decrementStep();
      }
    };

    getModels();
  }, []);

  return (
    <Row>
      {models.length > 0 ? (
        <>
          <Col xs={12}>
            <h3>{generateString(modelsStr.question, ['make'], [make])}</h3>
          </Col>
          {models.map(({model}) => {
            return (
              <Col xs={12} key={model} className="text-center">
                <Button
                  value={model}
                  className={'big-button'}
                  onClick={(e) => {
                    handleChange(e.target.value);
                    incrementStep();
                  }}
                >
                  {model}
                </Button>
              </Col>
            );
          })}
        </>
      ) : valueRetrieved ? (
        <Col xs={12} className={'d-flex justify-content-center'}>
          {modelsStr.noResults}
        </Col>
      ) : (
        <Col xs={12} className={'d-flex justify-content-center'}>
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </Col>
      )}
    </Row>
  );
};

export default Model;
