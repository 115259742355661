import {useContext, useState} from 'react';
import {Row, Col, Form, Button} from 'react-bootstrap';
import validator from 'validator';

import {GlobalContext} from '../../../../context/GlobalState';

import {handleEnterClickOnInput} from '../../../../helper/Functions';
import {orders} from '../../../../helper/Values';
import {emailStr} from '../../../../helper/Strings';

const Email = () => {
  const [empty, setEmpty] = useState(false);
  const {steps, handleChange, incrementStep} = useContext(GlobalContext);

  const handleEmailSubmission = () => {
    if (validator.isEmail(steps[orders.stepEmail].value)) incrementStep();
    else setEmpty(true);
  };

  return (
    <Row>
      <Col xs={12}>
        <Form
          onSubmit={(event) => {
            handleEnterClickOnInput(event, () => {
              handleEmailSubmission();
            });
          }}
        >
          <Form.Group controlId="emailAddress">
            <Form.Label>
              <h3>{emailStr.question}</h3>
            </Form.Label>
            <Form.Control
              isInvalid={empty}
              type="text"
              placeholder={emailStr.placeholder}
              autoFocus
              value={steps[orders.stepEmail].value}
              onChange={(e) => {
                setEmpty(false);
                handleChange(e.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid">
              {emailStr.validation}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Col>
      <Col xs={12} className="text-center">
        <Button
          onClick={() => {
            handleEmailSubmission();
          }}
          className="next-button"
        >
          {emailStr.button}
        </Button>
      </Col>
    </Row>
  );
};

export default Email;
