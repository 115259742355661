import {useContext} from 'react';
import Select from 'react-select';
import {Row, Col, Button} from 'react-bootstrap';

import {GlobalContext} from '../../../context/GlobalState';

const TemplateButtonsSelectQuestion = ({
  question,
  selectPlaceholder,
  buttonOptions,
  selectOptions,
  buttonsWidth,
  buttonsSmallerWidth,
  classes,
}) => {
  const {handleChange, incrementStep} = useContext(GlobalContext);

  let selectOptionsFormattedArray = [];
  selectOptions.forEach((option) => {
    selectOptionsFormattedArray.push({
      value: option,
      label: option,
    });
  });

  return (
    <Row>
      <Col xs={12}>
        <h3>{question}</h3>
      </Col>
      {buttonOptions.map((option) => {
        return (
          <Col
            xs={buttonsSmallerWidth ? buttonsSmallerWidth : buttonsWidth}
            md={buttonsWidth}
            key={option}
            className="text-center"
          >
            <Button
              value={option}
              className={`${classes}`}
              onClick={(e) => {
                handleChange(e.target.value);
                incrementStep();
              }}
            >
              {option}
            </Button>
          </Col>
        );
      })}
      <Col xs={12}>
        <Select
          className="select"
          options={selectOptionsFormattedArray}
          placeholder={selectPlaceholder}
          onChange={(selectedOption) => {
            handleChange(selectedOption.value);
            incrementStep();
          }}
        ></Select>
      </Col>
    </Row>
  );
};

export default TemplateButtonsSelectQuestion;
