import {useContext, useState} from 'react';
import {Row, Col, Form, Button} from 'react-bootstrap';
import validator from 'validator';

import {GlobalContext} from '../../../../context/GlobalState';

import {phoneStr} from '../../../../helper/Strings';

import {
  handleEnterClickOnInput,
  getNumbersToArray,
} from '../../../../helper/Functions';
import {orders} from '../../../../helper/Values';

const Phone = () => {
  const {steps, handleChange, incrementStep} = useContext(GlobalContext);
  const [empty, setEmpty] = useState(false);

  const handlePhoneSubmission = () => {
    let value = steps[orders.stepPhone].value;
    if (validator.isMobilePhone(value, 'en-US')) {
      handleChange(value);
      incrementStep();
    } else setEmpty(true);
  };

  const formatPhoneValue = async (value) => {
    let n = await getNumbersToArray(value);
    let str = '';

    for (let i = 0; i < 10; ++i) {
      if (!n[i]) n[i] = '';
    }
    if (value.length < 4 || value.length === 5) str = `${n[0]}${n[1]}${n[2]}`;
    else if (value.length < 10)
      str = `(${n[0]}${n[1]}${n[2]}) ${n[3]}${n[4]}${n[5]}`;
    else
      str = `(${n[0]}${n[1]}${n[2]}) ${n[3]}${n[4]}${n[5]}-${n[6]}${n[7]}${n[8]}${n[9]}`;

    return str;
  };

  return (
    <Row>
      <Col xs={12}>
        <Form
          onSubmit={(event) => {
            handleEnterClickOnInput(event, () => {
              handlePhoneSubmission();
            });
          }}
        >
          <Form.Group controlId="phoneNumber">
            <Form.Label>
              <h3>{phoneStr.question}</h3>
            </Form.Label>
            <Form.Control
              isInvalid={empty}
              type="text"
              placeholder={phoneStr.placeholder}
              autoFocus
              value={steps[orders.stepPhone].value}
              onChange={async (e) => {
                setEmpty(false);
                const value = e.target.value;
                const lastButtonClicked = e.nativeEvent.data;
                if (
                  // Check if lastButtonClicked defined and it's not number, because might an auto complete and this means
                  // the lastButton will be undefined and that's not a number and this means it will disable auto complete
                  (lastButtonClicked && isNaN(lastButtonClicked)) ||
                  value.length > 14
                )
                  return;

                let formattedValue = await formatPhoneValue(value);
                handleChange(formattedValue);
              }}
            />
            <Form.Control.Feedback type="invalid">
              {phoneStr.validation}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Col>
      <Col xs={12} className="text-center">
        <Button
          onClick={() => {
            handlePhoneSubmission();
          }}
          className="next-button submit-button"
        >
          {phoneStr.button}
        </Button>
      </Col>
      <Col>
        <h6 style={{textAlign: 'justify'}}>
          {`By clicking "Submit" I am providing my electronic signature and
          express written consent for InsureNerds, LLC to have one licensed
          insurance agency and their agents contact me by phone, text, email or
          US Postal mail for the purpose of providing me with a quote on my
          insurance. I understand that my consent is not a condition of purchase
          of any property, goods or services and I may withdraw my consent at
          any time. My consent is given even if my number is on a Do Not Call
          Registry and the use of an Automatic Telephone Dialer system,
          prerecorded voice, text or email system may be used. I acknowledge
          that I have read the `}
          <a href="https://insurenerds.com/privacy-policy/" target="_blank">
            Privacy Policy
          </a>
          {`, understand and agree to it and
          consent to InsureNerds sharing my information with the licensed
          insurance agency for the purpose of obtaining an insurance quote.`}
        </h6>
      </Col>
    </Row>
  );
};

export default Phone;
