import {useContext, useEffect, useState} from 'react';
import {Row, Col} from 'react-bootstrap';
import {useHistory, useLocation} from 'react-router-dom';

import {GlobalContext} from '../../context/GlobalState';

import {orders} from '../../helper/Values';

import FormHeader from './Header/HeaderHome';
import FormBody from './Body/BodyHome';
import ZipCode from './Body/ZipCode';

const Form = () => {
  const {
    currentStep,
    currentURL,
    isGoingBackDirection,
    transitions,
    updateStep,
    updateNewTab,
    incrementStep,
    checkToBeSkipped,
    decrementStep,
  } = useContext(GlobalContext);

  const history = useHistory();
  const search = useLocation().search;
  const code = new URLSearchParams(search).get('code');

  let embeddedFlag = new URLSearchParams(search).get('embedded');
  if (!embeddedFlag) embeddedFlag = 'No';
  const [embedded] = useState(embeddedFlag.toLowerCase());

  useEffect(() => {
    if (code) {
      updateStep(code, undefined, orders.stepZipCode);
      updateNewTab(true);
      incrementStep();
    }
  });

  useEffect(() => {
    if (checkToBeSkipped()) {
      if (isGoingBackDirection) {
        decrementStep();
      } else {
        incrementStep();
      }
    }
  }, [transitions]);

  useEffect(() => {
    history.push(currentURL);
  }, [transitions, currentURL]);

  window.onpopstate = (e) => {
    decrementStep();
    history.push(currentURL);
  };

  return (
    <>
      {currentStep === orders.stepZipCode ? (
        <Row>
          <Col
            xs={{span: 8, offset: 2}}
            sm={{span: 6, offset: 3}}
            md={{span: 4, offset: 4}}
            className={embedded === 'yes' ? '' : 'center-page'}
          >
            <ZipCode></ZipCode>
          </Col>
        </Row>
      ) : (
        <Row className="justify-content-center">
          {currentStep !== orders.stepZipCode ? (
            <Col xs={12} className={'form-header'}>
              <FormHeader></FormHeader>
            </Col>
          ) : (
            <></>
          )}
          <Col xs={12}>
            <FormBody></FormBody>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Form;
