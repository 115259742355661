import {useContext} from 'react';
import {Row, Col, Image} from 'react-bootstrap';

import {GlobalContext} from '../context/GlobalState';

import {orders} from '../helper/Values';

const Header = () => {
  const {currentStep} = useContext(GlobalContext);

  return (
    <>
      {currentStep !== orders.stepZipCode ? (
        <Row>
          <Col className="text-center">
            <a href="/">
              <Image
                src="/images/logo.png"
                style={{['maxWidth']: '10%', ['marginTop']: '10px'}}
              />
            </a>
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </>
  );
};

export default Header;
