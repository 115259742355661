import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {Container, Row, Col} from 'react-bootstrap';

import {GlobalProvider} from './context/GlobalState';
import Header from './components/Header';
import Body from './components/Body';
import Footer from './components/Footer';

import './App.css';
import './custom.scss';

function App() {
  return (
    <GlobalProvider>
      <Container>
        <Header></Header>
        <Router>
          <Switch>
            <Route path="/">
              <Body></Body>
            </Route>
          </Switch>
        </Router>
        {/* <Footer></Footer> */}
      </Container>
    </GlobalProvider>
  );
}

export default App;
