export const progressBarStr = `You are this close to smarter savings`;

export const zipCodeStr = {
  question: `Zip Code`,
  validation: `Please provide a Zip Code`,
  placeholder: `Zip Code`,
  button: `Begin`,
};
//
export const yearsStr = {
  question: `What is the Year of your vehicle?`,
  selectPlaceholder: `Choose other years`,
};

export const makesStr = {
  question: `What is the Make of your vehicle?`,
  selectPlaceholder: `Choose Other`,
};

export const modelsStr = {
  question: `What is the model of your {make}?`,
  noResults: `No models were retrieved, go back and correct car information`,
};

export const subModelsStr = {
  question: `What is the sub-model of your {make} {model}?`,
  noResults: `No sub models were retrieved, go back and correct car information`,
};

export const makingPaymentsStr = {
  question: `Are you still making payments on the "{year} {make} {model}", is it leased or do you own it and don't owe any money on it?`,
};

export const usageStateStr = {
  question: `How is the "{year} {make} {model}" used?`,
};

export const milesPerYearArr = {
  question: `How many miles do you drive the "{year} {make} {model}" each year on average?`,
};

export const wantOtherVehicle = {
  question: `Do you want another vehicle?`,
};

export const insuredWithGeneralOptionsStr = {
  question: `Who are you currently insured with?`,
  selectPlaceholder: `Choose Other`,
};

export const howLongWithInsuranceCompanyStr = {
  question: `How long have you been insured with "{company}"?`,
};

export const howLongContinuousCoverageStr = {
  question: `How long have you had continuous coverage including before being insured with "{company}"?`,
};

export const bodilyInjuryLimitStr = {
  question: `What are your current Bodily Injury liability limits?`,
};

export const howLongSinceInsuranceStr = {
  question: `How long since you last had insurance?`,
};

export const liabilityLimitLikedStr = {
  question: `What liability limits would you like?`,
};

export const howManyDriversStr = {
  question: `How many drivers in the household are there?`,
};

export const driverStr = {
  firstName: {
    questionSelf: `Your First Name`,
    questionOthers: `{ordinal} Driver's First Name`,
    placeholder: `First Name`,
    validation: `Please Enter First Name`,
  },
  lastName: {
    questionSelf: `Your Last Name`,
    questionOthers: `{ordinal} Driver's Last Name`,
    placeholder: `Last Name`,
    validation: `Please Enter Last Name`,
  },
  nameButton: `Next`,
  relationship: {
    question: `What is the {ordinal} Driver's Relationship to You`,
  },
  gender: {
    questionSelf: `Your Gender`,
    questionOthers: `{firstName}'s Gender`,
  },
  dateOfBirth: {
    questionSelf: `Your Date of Birth`,
    questionOthers: `{firstName}'s Date of Birth`,
    placeholder: `MM/DD/YYYY`,
    validation: `Please input date of birth in the form MM/DD/YYYY format`,
    button: `Next`,
  },
  martialStatus: {
    questionSelf: `Your Martial Status`,
    questionOthers: `{firstName}'s Martial Status`,
  },
  education: {
    questionSelf: `Your Education Level`,
    questionOthers: `{firstName}'s Education Level`,
  },
  occupation: {
    questionSelf: `Your Occupation`,
    questionOthers: `{firstName}'s Occupation`,
  },
};

export const ownHomeStr = {
  question: `Do you own a home?`,
};

export const duiStr = {
  question: `Has anyone had any DUI's or Reckless driving tickets in the past 5 years?`,
};

export const srFrStr = {
  question: `Does anyone require an SR-22 or FR-44?`,
};

export const accidentsStr = {
  question: `How many at fault accidents have the drivers had in the past 3 years?`,
};

export const ticketsStr = {
  question: `How many speeding tickets or other minor violations have the drivers had in the past 3 years`,
};

export const homeAddressStr = {
  question: `Home Address:`,
  placeholder: `Input your home address`,
  validation: `Please input your home address`,
  button: 'Confirm',
};

export const howLongAddressStr = {
  question: `How long have you been at this address?`,
};

export const emailStr = {
  question: `Email:`,
  placeholder: `Enter your email address`,
  validation: `Please input a valid email address`,
  button: 'Confirm',
};

export const phoneStr = {
  question: `Phone:`,
  placeholder: `Enter your phone number`,
  validation: `Please input a valid US phone number`,
  button: `Submit`,
};

export const successStr = `Thank you for your submission. A licensed insurance producer will reach out to you shortly with quotes from up to 8 different insurance companies.`;

export const failureStr = `Failed submitting your information, please try to submit again.`;

export const resubmitButtonStr = `Resubmit data`;
