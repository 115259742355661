import {Row, Col} from 'react-bootstrap';

import {progressBarStr} from '../../../helper/Strings';
const FirstPart = () => {
  return (
    <Row>
      <Col xs={12} className="text-center">
        {progressBarStr}
      </Col>
    </Row>
  );
};

export default FirstPart;
