import {useContext, useState, useEffect} from 'react';
import {Row, Col, Form, Button} from 'react-bootstrap';
import ordinal from 'ordinal';

import {GlobalContext} from '../../../../context/GlobalState';

import {
  generateString,
  handleEnterClickOnInput,
} from '../../../../helper/Functions';
import {orders} from '../../../../helper/Values';
import {driverStr} from '../../../../helper/Strings';

const Name = () => {
  const [emptyFirstName, setEmptyFirstName] = useState(false);
  const [emptyLastName, setEmptyLastName] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const {
    currentRepeater,
    steps,
    updateFirstName,
    updateLastName,
    handleChange,
    incrementStep,
  } = useContext(GlobalContext);

  useEffect(() => {
    const defaultValue =
      steps[orders.stepDrivers].value[currentRepeater][orders.stepDriversName];

    const firstNameDefaultValue = defaultValue.firstName;
    setFirstName(firstNameDefaultValue);
    const lastNameDefaultValue = defaultValue.lastName;
    setLastName(lastNameDefaultValue);
  }, []);

  const handleNameSubmission = () => {
    if (firstName !== '' && lastName !== '') incrementStep();
    else {
      if (firstName === '') setEmptyFirstName(true);
      if (lastName === '') setEmptyLastName(true);
    }
  };

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.charCode === 13) {
      handleNameSubmission();
    }
  };

  return (
    <Row>
      <Col xs={12}>
        <Form
          onSubmit={(event) => {
            handleEnterClickOnInput(event, () => {
              handleNameSubmission();
            });
          }}
        >
          <Form.Group controlId="firstName">
            <Form.Label>
              <h3>
                {currentRepeater === 1
                  ? driverStr.firstName.questionSelf
                  : generateString(
                      driverStr.firstName.questionOthers,
                      ['ordinal'],
                      [ordinal(currentRepeater)]
                    )}
              </h3>
            </Form.Label>
            <Form.Control
              isInvalid={emptyFirstName}
              type="text"
              placeholder={driverStr.firstName.placeholder}
              autoFocus
              value={
                steps[orders.stepDrivers].value[currentRepeater][
                  orders.stepDriversName
                ].firstName
              }
              onChange={(e) => {
                const value = e.target.value;

                setEmptyFirstName(false);
                setFirstName(value);
                updateFirstName(value);
                handleChange(`${value} ${lastName}`);
              }}
            />
            <Form.Control.Feedback type="invalid">
              {driverStr.firstName.validation}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="lastName">
            <Form.Label>
              <h3>
                {currentRepeater === 1
                  ? driverStr.lastName.questionSelf
                  : generateString(
                      driverStr.lastName.questionOthers,
                      ['ordinal'],
                      [ordinal(currentRepeater)]
                    )}
              </h3>
            </Form.Label>
            <Form.Control
              isInvalid={emptyLastName}
              type="text"
              placeholder={driverStr.lastName.placeholder}
              value={
                steps[orders.stepDrivers].value[currentRepeater][
                  orders.stepDriversName
                ].lastName
              }
              onChange={(e) => {
                const value = e.target.value;

                setEmptyLastName(false);
                setLastName(value);
                updateLastName(value);
                handleChange(`${firstName} ${value}`);
              }}
              onKeyPress={handleKeypress}
            />
            <Form.Control.Feedback type="invalid">
              {driverStr.lastName.validation}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Col>
      <Col xs={12} className="text-center">
        <Button
          onClick={() => {
            handleNameSubmission();
          }}
          className="next-button"
        >
          {driverStr.nameButton}
        </Button>
      </Col>
    </Row>
  );
};

export default Name;
