import {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import {Row, Col, Button, Spinner} from 'react-bootstrap';

import {GlobalContext} from '../../../../context/GlobalState';

import {generateString} from '../../../../helper/Functions';
import {orders} from '../../../../helper/Values';
import {subModelsStr} from '../../../../helper/Strings';

const SubModels = () => {
  const {
    steps,
    currentStep,
    currentRepeater,
    isGoingBackDirection,
    handleChange,
    incrementStep,
    decrementStep,
  } = useContext(GlobalContext);

  const [subModels, setSubModels] = useState([]);
  const [valueRetrieved, setValueRetrieved] = useState(false);

  const year =
    steps[currentStep].value[currentRepeater][orders.stepVehiclesYear].value;
  const make =
    steps[currentStep].value[currentRepeater][orders.stepVehiclesMake].value;
  const model =
    steps[currentStep].value[currentRepeater][orders.stepVehiclesModel].value;

  useEffect(() => {
    const getSubModels = async () => {
      try {
        const subModelsRes = await axios.get(
          `/helper/subModels?year=${year}&make=${make}&model=${model}`
        );
        const subModels = subModelsRes.data;

        if (subModels.length > 0) {
          setSubModels(subModels);
          setValueRetrieved(true);
        } else {
          if (isGoingBackDirection) decrementStep();
          else incrementStep();
        }
      } catch (err) {
        console.dir(err, {depth: 10});
        decrementStep();
      }
    };

    getSubModels();
  }, []);

  return (
    <Row>
      {subModels.length > 0 ? (
        <>
          <Col xs={12}>
            <h3>
              {generateString(
                subModelsStr.question,
                ['make', 'model'],
                [make, model]
              )}
            </h3>
          </Col>
          {subModels.map(({trim}) => {
            return (
              <Col xs={12} key={trim} className="text-center">
                <Button
                  value={trim}
                  className={'big-button'}
                  onClick={(e) => {
                    handleChange(e.target.value);
                    incrementStep();
                  }}
                >
                  {trim}
                </Button>
              </Col>
            );
          })}
        </>
      ) : valueRetrieved ? (
        <Col xs={12} className={'d-flex justify-content-center'}>
          {subModelsStr.noResults}
        </Col>
      ) : (
        <Col xs={12} className={'d-flex justify-content-center'}>
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </Col>
      )}
    </Row>
  );
};

export default SubModels;
