import {useContext} from 'react';
import {Row, Col} from 'react-bootstrap';

import {GlobalContext} from '../../../../context/GlobalState';

import {orders} from '../../../../helper/Values';

import SaveResults from './SaveResults';

const EndHome = () => {
  const {currentStep} = useContext(GlobalContext);

  return (
    <Row>
      <Col xs={12} className={'d-flex justify-content-center'}>
        <SaveResults></SaveResults>
      </Col>
    </Row>
  );
};

export default EndHome;
