export default (state, action) => {
  switch (action.type) {
    case 'UPDATE_CURRENT_STEP':
      return {
        ...state,
        currentStep: action.payload,
      };
    case 'UPDATE_CURRENT_REPEATER':
      return {
        ...state,
        currentRepeater: action.payload,
      };
    case 'UPDATE_CURRENT_SUB_STEP':
      return {
        ...state,
        currentSubStep: action.payload,
      };
    case 'UPDATE_CURRENT_URL':
      return {
        ...state,
        currentURL: action.payload,
      };
    case 'UPDATE_SINGLE_STEP':
      return {
        ...state,
        steps: {
          ...state.steps,
          [action.payload.step]: {
            ...state.steps[action.payload.step],
            [action.payload.key]: action.payload.value,
          },
        },
      };
    case 'UPDATE_REPEATER_STEP':
      return {
        ...state,
        steps: {
          ...state.steps,
          [action.payload.step]: {
            ...state.steps[action.payload.step],
            value: {
              ...state.steps[action.payload.step].value,
              [action.payload.repeater]: {
                ...state.steps[action.payload.step].value[
                  action.payload.repeater
                ],
                [action.payload.subStep]: {
                  ...state.steps[action.payload.step].value[
                    action.payload.repeater
                  ][action.payload.subStep],
                  [action.payload.key]: action.payload.value,
                },
              },
            },
          },
        },
      };
    case 'ADD_NEW_VEHICLE':
      return {
        ...state,
        steps: {
          ...state.steps,
          [action.payload.currentStep]: {
            ...state.steps[action.payload.currentStep],
            value: {
              ...state.steps[action.payload.currentStep].value,
              [action.payload.indexOfNewVehicle]: action.payload.vehicleArr,
            },
          },
        },
      };
    case 'ADD_NEW_DRIVER':
      return {
        ...state,
        steps: {
          ...state.steps,
          [action.payload.driversStepIndex]: {
            ...state.steps[action.payload.driversStepIndex],
            value: {
              ...state.steps[action.payload.driversStepIndex].value,
              [action.payload.driverNumber]: action.payload.driverObj,
            },
          },
        },
      };
    case 'SET_DRIVERS_LIST':
      return {
        ...state,
        steps: {
          ...state.steps,
          [action.payload.driversStepIndex]: {
            ...state.steps[action.payload.driversStepIndex],
            value: action.payload.driversObj,
          },
        },
      };
    case 'UPDATE_NUMBER_OF_VEHICLES':
      return {
        ...state,
        numberOfVehicles: action.payload,
      };
    case 'UPDATE_IS_GOING_BACK_DIRECTION':
      return {...state, isGoingBackDirection: action.payload};
    case 'UPDATE_TRANSITIONS':
      return {...state, transitions: action.payload};
    case 'UPDATE_SENT_TO_BE_SAVED':
      return {...state, sentToBeSaved: action.payload};
    case 'UPDATE_NEW_TAB':
      return {...state, newTab: action.payload};
    default:
      return state;
  }
};
