import FirstPart from './FirstPart';
import Progress from './ProgressBar';

function Header() {
  return (
    <>
      <FirstPart></FirstPart>
      <br></br>
      <Progress></Progress>
    </>
  );
}

export default Header;
