import {useEffect, useContext, useState} from 'react';
import {Row, Col, ProgressBar} from 'react-bootstrap';

import {GlobalContext} from '../../../context/GlobalState';

import {orders} from '../../../helper/Values';

const Progress = () => {
  const [percentage, setPercentage] = useState(0);

  const {currentStep} = useContext(GlobalContext);

  useEffect(() => {
    const calculatedPercentage = Math.round(
      ((currentStep - 1) / (orders.stepLastStep - 1)) * 100
    );
    setPercentage(calculatedPercentage);
  }, [currentStep]);
  return (
    <Row>
      <Col xs={12}>
        <ProgressBar
          striped
          variant="success"
          now={percentage}
          label={`${percentage}%`}
        />
      </Col>
    </Row>
  );
};

export default Progress;
