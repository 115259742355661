import {orders, options} from '../helper/Values';

export const decideNextStep = ({
  currentStep,
  currentRepeater,
  currentSubStep,
  steps,
}) => {
  let nextStep, nextRepeater, nextSubStep, nextURL;

  const currentStepObj = steps[currentStep];
  const isRepeaterStep = currentStepObj.isRepeaterStep;

  // If Single step, just increase the step counter and reset the other counters
  if (!isRepeaterStep) {
    nextStep = currentStep + 1;
    nextRepeater = 1;
    nextSubStep = 1;
  }
  // If Repeater step, decide to increase counters or not based on the location inside the repeater at any single point
  else {
    ({nextStep, nextRepeater, nextSubStep} = getNextValuesRepeaterStep({
      currentStep,
      currentRepeater,
      currentSubStep,
      steps,
      currentStepObj,
      isRepeaterStep,
    }));
  }

  nextURL = generateNextURL({
    nextStep,
    nextRepeater,
    nextSubStep,
    steps,
  });

  return {
    nextStep,
    nextRepeater,
    nextSubStep,
    nextURL,
  };
};

const getNextValuesRepeaterStep = ({
  currentStep,
  currentRepeater,
  currentSubStep,
  currentStepObj,
  isRepeaterStep,
}) => {
  let nextStep, nextRepeater, nextSubStep;

  let numberOfRepeaters = 0;
  // If Repeater step, count the repeated items to use later to increase currentRepeater
  if (isRepeaterStep)
    numberOfRepeaters = countElementsInJSON(currentStepObj.value);
  const numberOfSubSteps = currentStepObj.numberOfSubSteps;

  // If Not Last Step, just increase the sub step counter alone
  if (currentSubStep !== numberOfSubSteps) {
    nextStep = currentStep;
    nextRepeater = currentRepeater;
    nextSubStep = currentSubStep + 1;
    // If Last Step but Not Last Repeater, increase the repeater counter, and reset the sub step counter
  } else if (
    currentRepeater !== numberOfRepeaters &&
    currentSubStep === numberOfSubSteps
  ) {
    nextStep = currentStep;
    nextRepeater = currentRepeater + 1;
    nextSubStep = 1;
    // If Last Step and also Last Repeater, increase the step counter and reset the repeater and sub steps counters
  } else if (
    currentRepeater === numberOfRepeaters &&
    currentSubStep === numberOfSubSteps
  ) {
    nextStep = currentStep + 1;
    nextRepeater = 1;
    nextSubStep = 1;
  }

  return {nextStep, nextRepeater, nextSubStep};
};

const generateNextURL = ({nextStep, nextRepeater, nextSubStep, steps}) => {
  let isNextStepSingleStep = steps[nextStep].isRepeaterStep ? false : true;
  let nextURL;

  if (isNextStepSingleStep) nextURL = `/${steps[nextStep].url}`;
  else
    nextURL = `/${steps[nextStep].url}/${nextRepeater}/${steps[nextStep].value[nextRepeater][nextSubStep].url}`;

  return nextURL;
};
// ------------------------------------------------------ //

export const decidePreviousStep = ({
  currentStep,
  currentRepeater,
  currentSubStep,
  steps,
}) => {
  let previousStep, previousRepeater, previousSubStep, previousURL;

  const currentStepObj = steps[currentStep];
  const previousStepObj = steps[currentStep - 1];
  const isRepeaterStep = currentStepObj.isRepeaterStep;
  const isRepeaterFlag = currentStepObj.isRepeaterFlag;
  const followingRepeater = currentStepObj.followingRepeater;

  let numberOfRepeaters = 0;
  // If Repeater step, count the repeated items to use later to increase currentRepeater
  if (isRepeaterStep)
    numberOfRepeaters = countElementsInJSON(currentStepObj.value);
  const numberOfSubSteps = currentStepObj.numberOfSubSteps;
  const isStepFollowingInsuranceAndNotInsured =
    currentStep === orders.stepDrivers &&
    steps[orders.stepInsuranceQuestion1].value ===
      options.insuredWithGeneralOptionsArray[0]
      ? true
      : false;

  // If Repeater Flag Step, get the previous repeaters number and previous sub steps, reduce current step counter and assign value to previous repeater and previous sub steps counters
  if (isRepeaterFlag || followingRepeater) {
    let previousNumberOfRepeaters = countElementsInJSON(previousStepObj.value);
    let previousNumberOfSubSteps = previousStepObj.numberOfSubSteps;

    previousStep = currentStep - 1;
    previousRepeater = previousNumberOfRepeaters;
    previousSubStep = previousNumberOfSubSteps;
  }
  // If Not Repeater Step (For sure not Repeater Flag too because of condition above), decrease the previous step counter and reset other counters
  else if (!isRepeaterStep) {
    previousStep = currentStep - 1;
    previousRepeater = 1;
    previousSubStep = 1;
    // If the step following insurance fourth question, check if it's not insured already then
    // decrease the counter by 2 instead of 1 as there is no question in the immediate previous step
  } else if (isStepFollowingInsuranceAndNotInsured) {
    previousStep = currentStep - 2;
    previousRepeater = 1;
    previousSubStep = 1;
  }
  // If Repeater Step, decide to decrease counters or not based on the location inside the repeater at any single point
  else if (isRepeaterStep) {
    ({previousStep, previousRepeater, previousSubStep} =
      getPreviousValuesRepeaterStep({
        currentStep,
        currentRepeater,
        currentSubStep,
        steps,
      }));
  }

  previousURL = generatePreviousURL({
    previousStep,
    previousRepeater,
    previousSubStep,
    steps,
  });

  return {
    previousStep,
    previousRepeater,
    previousSubStep,
    previousURL,
  };
};

const getPreviousValuesRepeaterStep = ({
  currentStep,
  currentRepeater,
  currentSubStep,
  steps,
}) => {
  let previousStep, previousRepeater, previousSubStep, previousURL;

  const currentStepObj = steps[currentStep];
  const previousStepObj = steps[currentStep - 1];
  const isRepeaterStep = currentStepObj.isRepeaterStep;
  const isRepeaterFlag = currentStepObj.isRepeaterFlag;

  let numberOfRepeaters = 0;
  // If Repeater step, count the repeated items to use later to decrease currentRepeater
  if (isRepeaterStep)
    numberOfRepeaters = countElementsInJSON(currentStepObj.value);
  const numberOfSubSteps = currentStepObj.numberOfSubSteps;
  const previousNumberOfSubSteps = previousStepObj.numberOfSubSteps;

  if (currentSubStep !== 1) {
    // If Not First Step, just reduce the sub step
    previousStep = currentStep;
    previousRepeater = currentRepeater;
    previousSubStep = currentSubStep - 1;
  }
  // If First Step but Not First Repeater Step, reduce the repeater and set the sub steps to the sub steps number
  else if (currentSubStep === 1 && currentRepeater !== 1) {
    previousStep = currentStep;
    previousRepeater = currentRepeater - 1;
    previousSubStep = numberOfSubSteps;
  }
  // If First Step and also First Repeater Step, reduce the step counter and reset the rest of counters
  else if (currentSubStep === 1 && currentRepeater === 1) {
    // TODO: This won't support if the previous step was a repeater too, so can be improved later to handle that too
    previousStep = currentStep - 1;
    previousRepeater = 1;
    previousSubStep = 1;
  }
  return {previousStep, previousRepeater, previousSubStep};
};

const generatePreviousURL = ({
  previousStep,
  previousRepeater,
  previousSubStep,
  steps,
}) => {
  let isPreviousStepSingleStep = steps[previousStep].isRepeaterStep
    ? false
    : true;

  let previousURL;
  if (isPreviousStepSingleStep) previousURL = `/${steps[previousStep].url}`;
  else
    previousURL = `/${steps[previousStep].url}/${previousRepeater}/${steps[previousStep].value[previousRepeater][previousSubStep].url}`;

  return previousURL;
};
// ------------------------------------------------------ //

export const countElementsInJSON = (obj) => {
  return Object.keys(obj).length;
};

export const generateYearsArray = () => {
  let currentYear = new Date().getFullYear();
  if (options.numberOfVehicleYearsShowNextYear) currentYear += 1;

  let years = [];
  let startYear = currentYear - options.numberOfVehicleYearsToShow + 1;
  for (let i = currentYear; i >= startYear; i--) {
    years.push(currentYear--);
  }
  return years;
};

export const handleEnterClickOnInput = (event, action) => {
  event.preventDefault();
  action();
};

export const getNumbersToArray = async (value) => {
  return value.replace(/\D+/g, '').split('');
};

export const generateString = (string, variables, values) => {
  variables.forEach((item, index) => {
    string = string.replace(`{${item}}`, values[index]);
  });
  return string;
};

export const setPageTitle = (title) => {
  if (typeof title !== 'string') {
    throw new Error('Title should be an string');
  }
  document.title = title;
};
