import {useEffect, useContext} from 'react';

import {GlobalContext} from '../context/GlobalState';

import Form from './Form/Form';

const Body = () => {
  // This is just for testing purposes, this and below comments are to simply start testing from the required step
  const {updateCurrentStep, updateCurrentSubStep} = useContext(GlobalContext);

  useEffect(() => {
    // This is just for testing purposes, this and above comments are to simply start testing from the required step
    // updateCurrentStep(10);
    // updateCurrentSubStep(4);
  }, []);

  return <Form></Form>;
};

export default Body;
